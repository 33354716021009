import React from 'react';
import {
  JumpSpinnerBall,
  JumpSpinnerBallWrapper,
  JumpSpinnerBouncingLoader,
  JumpSpinnerLoaderWrapper,
  JumpSpinnerPlatform,
  JumpSpinnerWater,
} from './JumpSpinner.styles';

const JumpSpinner = () => (
  <div data-cy="jump-spinner" aria-label="Loading...">
    <JumpSpinnerLoaderWrapper>
      <JumpSpinnerBouncingLoader>
        <JumpSpinnerBallWrapper>
          <JumpSpinnerBall />
        </JumpSpinnerBallWrapper>
        <JumpSpinnerPlatform />
        <JumpSpinnerPlatform className="jump-spinner-platform2" />
        <JumpSpinnerPlatform className="jump-spinner-platform3" />
        <JumpSpinnerWater />
      </JumpSpinnerBouncingLoader>
    </JumpSpinnerLoaderWrapper>
  </div>
);

export default JumpSpinner;
