import JumpSpinner from 'components/common/spinners/JumpSpinner';
import { usePageQuery } from 'generated/graphql';
import { useMe } from 'hooks/useMe';
import { useParams } from 'next/navigation';
import { MainLayout } from 'views/global/MainLayout';
import { PageModule } from 'views/pages/start/PageModule';
import NextError from './_error';

const ModulePage = () => {
  const params = useParams();

  const slug = (params?.slug as string) ?? '';
  const { adminUserType } = useMe();

  const modulePage = usePageQuery({
    variables: { slug, previewUserType: adminUserType },
    skip: !slug,
    fetchPolicy: adminUserType ? 'network-only' : 'cache-first',
  });

  if (modulePage.loading) {
    return (
      <MainLayout fullWidth>
        <JumpSpinner />
      </MainLayout>
    );
  }

  if (!slug) {
    return <NextError statusCode={404} />;
  }

  if (!modulePage.data?.page) {
    return <NextError statusCode={404} />;
  }

  const { page } = modulePage.data;

  return (
    <MainLayout fullWidth>
      {page.modules.map((module, index) => (
        <PageModule module={module} key={module.__typename} index={index} />
      ))}
    </MainLayout>
  );
};

export default ModulePage;
