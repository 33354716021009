import styled, { keyframes } from 'styled-components';

const spikeMove = keyframes`
    0% {
      transform: translate3d(0, 15px, 0);
    }
    50% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, 15px, 0);
    }
`;
const move = keyframes`
    from {
      transform: translate3d(-17px, 0, 0);
    }
    to {
      transform: translate3d(120px, 0, 0);
    }
`;
const bounce = keyframes`
    0% {
      transform: translate3d(0, 25px, 0);
    }
    100% {
      transform: translate3d(0, 58px, 0);
    }
`;

export const JumpSpinnerLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10vh auto 10vh 0px;
`;

export const JumpSpinnerBouncingLoader = styled.div`
  background-color: #3c3e4a;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  border: 0px solid #17191a;
  mask-image: radial-gradient(white, black);
`;

export const JumpSpinnerBallWrapper = styled.div`
  animation: ${move} 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const JumpSpinnerBall = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  animation: ${bounce} 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
`;

export const JumpSpinnerPlatform = styled.div`
  position: absolute;
  bottom: 45px;
  left: 10px;
  background-color: #00ac7b;
  width: 20px;
  height: 10px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  &.jump-spinner-platform2 {
    left: 50px;
    background-color: #5a768a;
    clip-path: polygon(50% 0%, 67% 54%, 92% 2%, 100% 57%, 100% 100%, 55% 100%, 0 100%, 0 56%, 10% 0, 34% 53%);
    height: 30px;
    bottom: 40px;
    animation: ${spikeMove} 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  &.jump-spinner-platform3 {
    left: 83px;
    background-color: #e81623;
  }
`;

export const JumpSpinnerWater = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #2b3363;
  width: 100%;
  height: 45px;
`;
